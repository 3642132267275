/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Text, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, TitleMain, Subtitle, Image, SeparateLine, SeparateLineWrap, YouTube, ContactForm, Fullmap, FullmapWrap, FullmapCover } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <ColumnCover name={"menu"} fullscreen={false}>
          
          <Column className="--menu --style5 pb--08 pt--08" menu={true} fullscreen={false}>
            
            <Menu fullscreen={false}>
              
              <ColumnWrapper className="menu-logo-box pl--08" style={{"paddingLeft":null}} fullscreen={false}>
                
                <Text className="text-box fs--30 text-box--invert ls--002 lh--1" content={"<span style=\"font-weight: bold;\">Minibagry z Černotína</span>"} fullscreen={false}>
                </Text>

                <Text className="text-box fs--30 mt--0" style={{"color":"var(--color-variable-1)"}} content={"<span style=\"color: rgb(255, 255, 255); font-weight: bold;\">Černotín 11 Dnešice<br>Plzeň-jih</span><br>"} fullscreen={false}>
                </Text>

              </ColumnWrapper>

              <MenuWrap fullscreen={false}>
                
                <MenuButton fullscreen={false}>
                </MenuButton>

                <MenuWrapBox fullscreen={false}>
                  
                  <MenuButtonClose fullscreen={false}>
                  </MenuButtonClose>

                  <Button className="btn-box btn-box--hvr2 btn-box--sbtn2 fs--16" href={"#fotogalerie"} target={""} content={"Fotogalerie"} fullscreen={false}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--sbtn2 fs--16" href={"#kontakt"} target={""} content={"Kontakt"} fullscreen={false}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--sbtn2 fs--16" href={"#kontakt"} target={""} content={"Rezervace"} fullscreen={false}>
                  </Button>

                </MenuWrapBox>

              </MenuWrap>

            </Menu>

          </Column>

        </ColumnCover>


        <Column className="css-gn33lv --style3 bg--center --full" name={"uvod"} fullscreen={true} css={css`
        
    background-image: linear-gradient(rgba(17,20,28,0.35), rgba(17,20,28,0.35)), url(https://cdn.swbpg.com/t/13064/9439ab044d32444c8d85e1ec993053c0_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(17,20,28,0.35), rgba(17,20,28,0.35)), url(https://cdn.swbpg.com/t/13064/9439ab044d32444c8d85e1ec993053c0_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(17,20,28,0.35), rgba(17,20,28,0.35)), url(https://cdn.swbpg.com/t/13064/9439ab044d32444c8d85e1ec993053c0_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(17,20,28,0.35), rgba(17,20,28,0.35)), url(https://cdn.swbpg.com/t/13064/9439ab044d32444c8d85e1ec993053c0_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(17,20,28,0.35), rgba(17,20,28,0.35)), url(https://cdn.swbpg.com/t/13064/9439ab044d32444c8d85e1ec993053c0_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(17,20,28,0.35), rgba(17,20,28,0.35)), url(https://cdn.swbpg.com/t/13064/9439ab044d32444c8d85e1ec993053c0_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(17,20,28,0.35), rgba(17,20,28,0.35)), url(https://cdn.swbpg.com/t/13064/9439ab044d32444c8d85e1ec993053c0_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(17,20,28,0.35), rgba(17,20,28,0.35)), url(https://cdn.swbpg.com/t/13064/9439ab044d32444c8d85e1ec993053c0_s=3000x_.jpeg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--72 title-box--invert mt--10" style={{"marginTop":null}} content={"Plzeňský kraj<br>"}>
              </Title>

              <TitleMain className="title-box fs--62 title-box--invert swpf--uppercase mt--0" style={{"marginTop":null}} content={"Centrum minibagrů Pro Vás<br>"}>
              </TitleMain>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"ibmi3v2r8hk"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box fs--48" content={"Vaše první originální značková půjčovna Kubota<br>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"font-weight: bold; color: rgb(0, 7, 78);\">Půjčujeme bez čekání jednoduše špičková minirypadla Kubota pro provedení zemních prací u Vás doma. Pokud chcete rychle a levně vykopat menší základy , srovnat terén, upravit zahradu, nebo postavit plot , nechcete čekat nebo jen z prostorových důvodů nedostanete větší techniku na Váš pozemek pak jste na správné adrese.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"sluzby"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--shadow2 fs--43" content={"9 důvodů proč naši Kubotu???"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--16 pt--16" name={"sluzby-2"} layout={"l23"}>
          
          <ColumnWrap className="column__flex el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13064/df28a0443de84f12b4c147bb4b0e6255_s=660x_.jpeg"} svg={false} style={{"maxWidth":100}} srcSet={"https://cdn.swbpg.com/t/13064/df28a0443de84f12b4c147bb4b0e6255_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/df28a0443de84f12b4c147bb4b0e6255_s=660x_.jpeg 660w"} content={null} svgColor={"rgba(39,39,39,1)"}>
              </Image>

              <Text className="text-box fs--16" content={"<span style=\"caret-color: rgb(219, 48, 15); color: rgb(219, 48, 15);\">Půjčujeme jen nové nebo zánovní stroje, žádné&nbsp;vyšeptané vraky u nás nehledejte.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13064/3d636f730bd8403a8f08d6c04935c729_s=660x_.jpeg"} svg={false} style={{"maxWidth":100}} srcSet={"https://cdn.swbpg.com/t/13064/3d636f730bd8403a8f08d6c04935c729_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/3d636f730bd8403a8f08d6c04935c729_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/3d636f730bd8403a8f08d6c04935c729_s=860x_.jpeg 860w"} content={null} svgColor={"rgba(39,39,39,1)"}>
              </Image>

              <Text className="text-box fs--16" content={"<span style=\"color: rgb(219, 48, 15);\"><span style=\"caret-color: rgb(0, 7, 78);\">&nbsp;Víme</span>&nbsp;co děláme, a dokážeme Vám poradit nejvhodnější variantu jak stroje tak postupu. Jsme zde 7dní v týdnu 24 hod.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13064/c918412851254ac28b7ba9e513de337a_s=860x_.jpeg"} svg={false} style={{"maxWidth":100}} srcSet={"https://cdn.swbpg.com/t/13064/c918412851254ac28b7ba9e513de337a_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/c918412851254ac28b7ba9e513de337a_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/c918412851254ac28b7ba9e513de337a_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13064/c918412851254ac28b7ba9e513de337a_s=1400x_.jpeg 1400w"} content={null} svgColor={"rgba(39,39,39,1)"}>
              </Image>

              <Text className="text-box fs--16" style={{"maxWidth":""}} content={"<span style=\"caret-color: rgb(219, 48, 15); color: rgb(219, 48, 15);\">Každý náš stroj má vlastní přepravní vlek, nic nepřekládáme, pouze Vám připojíme přívěs. Pro seznámení máme vyčleněný školicí bagr na zemi.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13064/70e9934473e9404eb041fc518ff2cd5f_s=860x_r=90_.jpeg"} svg={false} style={{"maxWidth":100}} srcSet={"https://cdn.swbpg.com/t/13064/70e9934473e9404eb041fc518ff2cd5f_s=350x_r=90_.jpeg 350w, https://cdn.swbpg.com/t/13064/70e9934473e9404eb041fc518ff2cd5f_s=660x_r=90_.jpeg 660w, https://cdn.swbpg.com/t/13064/70e9934473e9404eb041fc518ff2cd5f_s=860x_r=90_.jpeg 860w, https://cdn.swbpg.com/t/13064/70e9934473e9404eb041fc518ff2cd5f_s=1400x_r=90_.jpeg 1400w, https://cdn.swbpg.com/t/13064/70e9934473e9404eb041fc518ff2cd5f_s=2000x_r=90_.jpeg 2000w"} content={null} position={{"x":"-81%","y":"-5%"}} svgColor={"rgba(39,39,39,1)"}>
              </Image>

              <Text className="text-box fs--16" content={"<span style=\"color: rgb(219, 48, 15);\">Žádné skryté&nbsp;<span style=\"caret-color: rgb(0, 7, 78);\">poplatky</span>&nbsp;a kauce nevedeme, cenu víte předem a obdržíte ji v rezervační sms.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13064/3adbad126332482ba292221a177742d8_s=860x_.jpeg"} svg={false} style={{"maxWidth":100}} srcSet={"https://cdn.swbpg.com/t/13064/3adbad126332482ba292221a177742d8_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/3adbad126332482ba292221a177742d8_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/3adbad126332482ba292221a177742d8_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13064/3adbad126332482ba292221a177742d8_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/13064/3adbad126332482ba292221a177742d8_s=2000x_.jpeg 2000w"} content={null} position={{"x":"-7%","y":"-2%"}} svgColor={"rgba(39,39,39,1)"}>
              </Image>

              <Text className="text-box fs--16" content={"<span style=\"caret-color: rgb(219, 48, 15); color: rgb(219, 48, 15);\">Poskytujeme All inclusiv službu, vše co potřebujete k práci je součástí přepravního vleku.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13064/5c2d2785c7004154bd4343e96607e079_s=660x_.jpeg"} svg={false} style={{"maxWidth":100}} srcSet={"https://cdn.swbpg.com/t/13064/5c2d2785c7004154bd4343e96607e079_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/5c2d2785c7004154bd4343e96607e079_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/5c2d2785c7004154bd4343e96607e079_s=860x_.jpeg 860w"} content={null} svgColor={"rgba(39,39,39,1)"}>
              </Image>

              <Text className="text-box fs--16" content={"<span style=\"color: rgb(219, 48, 15);\"><span style=\"caret-color: rgb(0, 7, 78);\">Lopaty 20,40,80 cm jsou součástí základní ceny půjčovného</span><span style=\"caret-color: rgb(0, 7, 78);\">. Všechno nářadí je vybaveno rychloupínačem, nic nečepujete, výměna lžíce zabere 1 min.</span></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13064/fcb8fa93a89a4879a5f55a0c17fe8ee9_s=660x_.jpeg"} svg={false} style={{"maxWidth":100}} srcSet={"https://cdn.swbpg.com/t/13064/fcb8fa93a89a4879a5f55a0c17fe8ee9_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/fcb8fa93a89a4879a5f55a0c17fe8ee9_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/fcb8fa93a89a4879a5f55a0c17fe8ee9_s=860x_.jpeg 860w"} content={null} svgColor={"rgba(39,39,39,1)"}>
              </Image>

              <Text className="text-box fs--16" content={"<span style=\"caret-color: rgb(219, 48, 15); color: rgb(219, 48, 15);\">Půjčujeme jedničku ve své třídě, a víme co půjčujeme, za tím si i stojíme.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13064/70e9934473e9404eb041fc518ff2cd5f_s=860x_r=90_.jpeg"} svg={false} style={{"maxWidth":100}} srcSet={"https://cdn.swbpg.com/t/13064/70e9934473e9404eb041fc518ff2cd5f_s=350x_r=90_.jpeg 350w, https://cdn.swbpg.com/t/13064/70e9934473e9404eb041fc518ff2cd5f_s=660x_r=90_.jpeg 660w, https://cdn.swbpg.com/t/13064/70e9934473e9404eb041fc518ff2cd5f_s=860x_r=90_.jpeg 860w, https://cdn.swbpg.com/t/13064/70e9934473e9404eb041fc518ff2cd5f_s=1400x_r=90_.jpeg 1400w, https://cdn.swbpg.com/t/13064/70e9934473e9404eb041fc518ff2cd5f_s=2000x_r=90_.jpeg 2000w"} content={null} svgColor={"rgba(39,39,39,1)"}>
              </Image>

              <Text className="text-box fs--16" content={"<span style=\"color: rgb(219, 48, 15);\">Půjčíme Vám i příslušenství které jinde nenajdete a které Vám výrazně zpříjemní práci.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13064/1aa0db4e5e7b43d689226f3da3056894_s=660x_.jpeg"} svg={false} style={{"maxWidth":100}} srcSet={"https://cdn.swbpg.com/t/13064/1aa0db4e5e7b43d689226f3da3056894_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/1aa0db4e5e7b43d689226f3da3056894_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/1aa0db4e5e7b43d689226f3da3056894_s=860x_.jpeg 860w"} content={null} svgColor={"rgba(39,39,39,1)"}>
              </Image>

              <Text className="text-box fs--16" content={"<span style=\"caret-color: rgb(219, 48, 15); color: rgb(219, 48, 15);\">Pro jízdu s naší soupravou Vám stačí běžný řidičák skupiny B.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"oddelovaci-cara"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--30 pt--10" name={"cenik"} layout={"l4"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--shadow2 fs--43" content={"Naše technika"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--4 flex--top" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13064/70e9934473e9404eb041fc518ff2cd5f_s=860x_r=90_.jpeg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13064/70e9934473e9404eb041fc518ff2cd5f_s=350x_r=90_.jpeg 350w, https://cdn.swbpg.com/t/13064/70e9934473e9404eb041fc518ff2cd5f_s=660x_r=90_.jpeg 660w, https://cdn.swbpg.com/t/13064/70e9934473e9404eb041fc518ff2cd5f_s=860x_r=90_.jpeg 860w, https://cdn.swbpg.com/t/13064/70e9934473e9404eb041fc518ff2cd5f_s=1400x_r=90_.jpeg 1400w, https://cdn.swbpg.com/t/13064/70e9934473e9404eb041fc518ff2cd5f_s=2000x_r=90_.jpeg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"Kubota K008-3"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Minirypadlo 900 kg, prostup 72 cm<br><br>"}>
              </Text>

              <Subtitle className="subtitle-box w--500 ls--001" content={"1400,–Kč / den/ 6mth"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13064/fcb8fa93a89a4879a5f55a0c17fe8ee9_s=660x_.jpeg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13064/fcb8fa93a89a4879a5f55a0c17fe8ee9_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/fcb8fa93a89a4879a5f55a0c17fe8ee9_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/fcb8fa93a89a4879a5f55a0c17fe8ee9_s=860x_.jpeg 860w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" style={{"maxWidth":200}} content={"Kubota U10-3<br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Minirypadlo 1100 kg, větší základna a delší rameno, prostup 85 cm"}>
              </Text>

              <Subtitle className="subtitle-box w--500 ls--001" content={"1800,–Kč /den /6mth"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--4 flex--top" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13064/93a81346183445a3b7509cffedf78533_s=860x_.jpeg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13064/93a81346183445a3b7509cffedf78533_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/93a81346183445a3b7509cffedf78533_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/93a81346183445a3b7509cffedf78533_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13064/93a81346183445a3b7509cffedf78533_s=1400x_.jpeg 1400w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" style={{"maxWidth":200}} content={"Speciální příslušenství"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"<br>Různé typy lopat, hrábě, speciální kabelové lžíce<br><br>"}>
              </Text>

              <Subtitle className="subtitle-box w--500 ls--001" content={"od 200,–Kč/výpůjčka"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13064/2c8986db918349b5850969e03935a513_s=660x_.jpeg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13064/2c8986db918349b5850969e03935a513_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/2c8986db918349b5850969e03935a513_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/2c8986db918349b5850969e03935a513_s=860x_.jpeg 860w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" style={{"maxWidth":200}} content={"Vrtací jednotka"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Vrtání s možností vrtání 10,20,30 cm do hloubky cca 1m."}>
              </Text>

              <Subtitle className="subtitle-box w--500 ls--001" content={"1300 ,–Kč/den"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"fz6ndgmmzk"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Naše stroje v pohybu<br>"}>
              </Title>

              <Text className="text-box fs--22" content={"<span style=\"font-weight: bold; color: rgb(0, 7, 78);\">Ohlédnutí za uplynulým rokem</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"EcVxdvDZmp0"} autoplay={false}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-oy554u pb--30 pt--30" name={"cenik-2"} layout={"l1"} css={css`
        
    background-image: url(https://cdn.swbpg.com/t/13064/df28a0443de84f12b4c147bb4b0e6255_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13064/df28a0443de84f12b4c147bb4b0e6255_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/13064/df28a0443de84f12b4c147bb4b0e6255_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13064/df28a0443de84f12b4c147bb4b0e6255_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/13064/df28a0443de84f12b4c147bb4b0e6255_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/13064/df28a0443de84f12b4c147bb4b0e6255_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13064/df28a0443de84f12b4c147bb4b0e6255_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13064/df28a0443de84f12b4c147bb4b0e6255_s=3000x_.jpeg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--invert" content={"Možnost dlouhodobé spolupráce s firmami"}>
              </Title>

              <Text className="text-box fs--36 text-box--invert mt--06" style={{"marginTop":null}} content={"<span style=\"font-weight: bold;\">volejte 724277450</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"informace"} style={{"backgroundColor":"rgba(248, 248, 248, 1)"}} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" style={{"maxWidth":800}} content={"<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"oddelovaci-cara"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--l8w pb--30 pt--30" name={"fotogalerie"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Fotogalerie"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13064/df28a0443de84f12b4c147bb4b0e6255_s=660x_.jpeg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13064/df28a0443de84f12b4c147bb4b0e6255_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/df28a0443de84f12b4c147bb4b0e6255_s=660x_.jpeg 660w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13064/5bfc8a239c2d46cea0e7d3eb3265a963_s=860x_.jpeg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13064/5bfc8a239c2d46cea0e7d3eb3265a963_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/5bfc8a239c2d46cea0e7d3eb3265a963_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/5bfc8a239c2d46cea0e7d3eb3265a963_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13064/5bfc8a239c2d46cea0e7d3eb3265a963_s=1400x_.jpeg 1400w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13064/70e9934473e9404eb041fc518ff2cd5f_s=860x_r=90_.jpeg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13064/70e9934473e9404eb041fc518ff2cd5f_s=350x_r=90_.jpeg 350w, https://cdn.swbpg.com/t/13064/70e9934473e9404eb041fc518ff2cd5f_s=660x_r=90_.jpeg 660w, https://cdn.swbpg.com/t/13064/70e9934473e9404eb041fc518ff2cd5f_s=860x_r=90_.jpeg 860w, https://cdn.swbpg.com/t/13064/70e9934473e9404eb041fc518ff2cd5f_s=1400x_r=90_.jpeg 1400w, https://cdn.swbpg.com/t/13064/70e9934473e9404eb041fc518ff2cd5f_s=2000x_r=90_.jpeg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13064/ad572144ee3c4ee29f112295f128fcbc_s=660x_.jpeg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13064/ad572144ee3c4ee29f112295f128fcbc_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/ad572144ee3c4ee29f112295f128fcbc_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/ad572144ee3c4ee29f112295f128fcbc_s=860x_.jpeg 860w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13064/722e2384e6684c1ca6b302bbe8b3e904_s=860x_.jpeg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13064/722e2384e6684c1ca6b302bbe8b3e904_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/722e2384e6684c1ca6b302bbe8b3e904_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/722e2384e6684c1ca6b302bbe8b3e904_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13064/722e2384e6684c1ca6b302bbe8b3e904_s=1400x_.jpeg 1400w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13064/df794b0cb4fa4f5f95d807de0a57c0a6_s=860x_.jpeg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13064/df794b0cb4fa4f5f95d807de0a57c0a6_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/df794b0cb4fa4f5f95d807de0a57c0a6_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/df794b0cb4fa4f5f95d807de0a57c0a6_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13064/df794b0cb4fa4f5f95d807de0a57c0a6_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/13064/df794b0cb4fa4f5f95d807de0a57c0a6_s=2000x_.jpeg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-ieojhj --full pb--60 pt--60" name={"2lkn5sflnet"} fullscreen={true} css={css`
        
    background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/13064/68f195e96a24460f8da1300ee0997a33_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/13064/68f195e96a24460f8da1300ee0997a33_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/13064/68f195e96a24460f8da1300ee0997a33_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/13064/68f195e96a24460f8da1300ee0997a33_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/13064/68f195e96a24460f8da1300ee0997a33_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/13064/68f195e96a24460f8da1300ee0997a33_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/13064/68f195e96a24460f8da1300ee0997a33_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/13064/68f195e96a24460f8da1300ee0997a33_s=3000x_.jpeg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--102 title-box--invert" content={"Doprava stroje<br>"}>
              </Title>

              <Text className="text-box fs--26 text-box--invert" style={{"maxWidth":650}} content={"<span style=\"font-weight: bold;\">stroj si převezete sami na zapůjčeném bržděném &nbsp;vleku 1300 kg s řidičákem skupiny B<br>Cena vleku <br>1 den 300,-Kč<br></span><br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"kontakt"} layout={"l2"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Rezervace"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/i/moduls/email-flat-1.svg"} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":60}} svgColor={"rgba(39,39,39,1)"}>
              </Image>

              <Subtitle className="subtitle-box mt--02" content={"vladimir@prusik.cz<br>"}>
              </Subtitle>

              <Text className="text-box" content={"<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/i/moduls/phone-flat-1.svg"} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":60}} svgColor={"rgba(39,39,39,1)"}>
              </Image>

              <Subtitle className="subtitle-box mt--02" content={"+420 724277450"}>
              </Subtitle>

              <Text className="text-box" content={"Začněte psát text, tento prostor je určený pro text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/i/moduls/adress-flat-1.svg"} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":60}} svgColor={"rgba(39,39,39,1)"}>
              </Image>

              <Subtitle className="subtitle-box mt--02" content={"Černotín 11 Dnešice<br>33401 Plzeň-jih<br>"}>
              </Subtitle>

              <Text className="text-box" content={"<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <ContactForm className="--shape2 --shape5 --style1 --shadow5" action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Vaše celé  jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","required":true,"placeholder":"Zadejte Váš telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte přesnou adresu práce a termín"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"mapa"}>
          
          <FullmapWrap className="--full">
            
            <FullmapCover place={"Černotín Dnešice"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Column className="pb--10 pt--20" name={"paticka"} style={{"paddingTop":null,"paddingBottom":null,"backgroundColor":"var(--color-variable-1)"}}>
          
          <ColumnWrap className="column__flex el--3 flex--top" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--24 w--500 title-box--invert" content={"Minibagry z Černotína<br>První originální půjčovna<br>"}>
              </Title>

              <Text className="text-box fs--16 text-box--invert" content={"<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--26 w--500 title-box--invert" content={"Kontakt"}>
              </Title>

              <Subtitle className="subtitle-box w--500 subtitle-box--invert ls--001" content={"+420 724277450"}>
              </Subtitle>

              <Subtitle className="subtitle-box w--600 subtitle-box--invert mt--02" content={"vladimir@prusik.cz<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Text className="text-box fs--18 w--600 text-box--invert mt--0" content={"<a href=\"#uvod\" style=\"color: rgb(255, 255, 255);\">Úvod</a>"}>
              </Text>

              <Text className="text-box fs--18 w--600 text-box--invert mt--0" style={{"marginTop":null}} content={"<span style=\"text-decoration: underline;\">Proč Kubotu</span><br>"}>
              </Text>

              <Text className="text-box fs--18 w--600 text-box--invert mt--0" content={"<a href=\"#cenik\" style=\"color: rgb(255, 255, 255);\">Nabídka</a>"}>
              </Text>

              <Text className="text-box fs--18 w--600 text-box--invert mt--0" content={"<a href=\"#fotogalerie\" style=\"color: rgb(255, 255, 255);\">Fotogalerie</a>"}>
              </Text>

              <Text className="text-box fs--18 w--600 text-box--invert mt--0" content={"<a href=\"#kontakt\" style=\"color: rgb(255, 255, 255);\">Kontakt</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}